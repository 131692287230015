import "./App.css";

import * as React from "react";

import Terminal from "react-console-emulator";

const commands = {
  resume: {
    description: "Link to my most recent resume",
    usage: "",
    fn: (...args) =>
      `<a href="https://ryandetzel.com/ryan.detzel.resume.pdf" target="_blank" ref="noreferrer">https://ryandetzel.com/resume.pdf</a>`,
  },
  linkedin: {
    description: "LinkedIn link",
    usage: "",
    fn: () =>
      `<a href="https://www.linkedin.com/in/ryandetzel/" target="_blank" ref="noreferrer">https://www.linkedin.com/in/ryandetzel/</a>`,
  },
  github: {
    description: "Github link",
    usage: "",
    fn: () =>
      `<a href="https://github.com/ryanrdetzel" target="_blank" ref="noreferrer">https://github.com/ryanrdetzel</a>`,
  },
  hobbies: {
    description: "List of hobbies",
    usage: "",
    fn: () =>
      `In no particular order<br/>Programming, woodworking, metal working, home automation, 3d printing, electronics, sailing`,
  },
  contact: {
    description: "Email address",
    usage: "",
    fn: () => "ryan@detz.net",
  },
};

const myComponentStyle = {
  margin: "auto",
  minHeight: "400px",
  width: "800px",
};

function App() {
  const terminalRef = React.useRef();

  return (
    <>
      <div className="infoBox"></div>
      <Terminal
        ref={terminalRef}
        style={myComponentStyle}
        className="terminal"
        commands={commands}
        welcomeMessage={
          "Welcome to ryandetzel.com - type help below to get a list of available commands."
        }
        promptLabel={"$"}
        autoFocus={true}
        dangerMode={true}
      />
    </>
  );
}

export default App;
